import { default as accountLUTTRGMhg3Meta } from "/vercel/path0/pages/account.vue?macro=true";
import { default as indexWk8Hi46KRAMeta } from "/vercel/path0/pages/app/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
export default [
  {
    name: accountLUTTRGMhg3Meta?.name ?? "account",
    path: accountLUTTRGMhg3Meta?.path ?? "/account",
    meta: accountLUTTRGMhg3Meta || {},
    alias: accountLUTTRGMhg3Meta?.alias || [],
    redirect: accountLUTTRGMhg3Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account.vue").then(m => m.default || m)
  },
  {
    name: indexWk8Hi46KRAMeta?.name ?? "app",
    path: indexWk8Hi46KRAMeta?.path ?? "/app",
    meta: indexWk8Hi46KRAMeta || {},
    alias: indexWk8Hi46KRAMeta?.alias || [],
    redirect: indexWk8Hi46KRAMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  }
]